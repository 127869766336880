var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "svg-icons" }, [
    _c(
      "svg",
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
      [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            d:
              "M4.16 4.16l1.42 1.42A6.99 6.99 0 0 0 10 18a7 7 0 0 0 4.42-12.42l1.42-1.42a9 9 0 1 1-11.69 0zM9 0h2v8H9V0z"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }